<template>
  <ValidationObserver v-slot="{ invalid }">
    <form class="m-t" @submit.prevent="onSubmit" autocomplete="off">
      <div class="form-group">
        <ValidationProvider
          name="User Name"
          :rules="{ required: true }"
          v-slot="{ errors, classes }"
        >
          <input
            type="text"
            class="form-control"
            v-model="login.userId"
            placeholder="Username"
            :class="classes"
          />
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="form-group">
        <ValidationProvider name="Password" rules="required" v-slot="{ errors, classes }">
          <input
            type="password"
            v-model="login.password"
            class="form-control"
            placeholder="Password"
            :class="classes"
          />
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <button
        type="submit"
        class="btn btn-primary block full-width m-b ladda-button"
        data-style="zoom-in"
        :disabled="invalid"
      >
        Login
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
import IdleSessionTimeout from "../plugin/IdleSessionTimeout";
import Utils from "../plugin/Utils";

export default {
  data() {
    return {
      login: {
        userId: "",
        password: "",
      },
    };
  },
  mounted: function () {
    localStorage.clear();
    IdleSessionTimeout.session.dispose();
    this.fetchMenuList();
  },
  methods: {
    onSubmit() {
      this.login.password = Utils.getEncryptedPassword(this.login.password);
      const self = this;
      AxiosService.post(Constant.url.LOGIN_URL, this.login).then((result) => {
        if (result.responseCode == Constant.data.SUCCESS) {
          sessionStorage.setItem(Constant.data.SESSION_TOKE, result.token);
          localStorage.setItem(Constant.data.SESSION_USER, result.user);
          localStorage.setItem(Constant.data.IS_LOGIN, true);
          localStorage.setItem(Constant.data.USER_ROLE, result.roleGroup);
          localStorage.setItem(Constant.data.USER_ID, self.login.userId);
          self.$router.replace(Constant.url.HOME_URI);
          IdleSessionTimeout.session.start(); 
        }
      });
    },
    fetchMenuList() {
      AxiosService.getURL("/getServiceEndpoint").then((result) => {
        sessionStorage.setItem(Constant.data.SERVER_URL, result);
        setTimeout(() => {
          AxiosService.get(Constant.url.MENU_ALL).then((result) => {
            localStorage.setItem(Constant.data.MENU_LIST, JSON.stringify(result));
         });
        }, 400);
      });
    },
  },
};
</script>
